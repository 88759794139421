import Terms from '@/components/Terms/Terms';
import { VITE_INITIAL_CDN_PATH } from '@/constants/constants';
import { useBrand } from '@/hooks/useBrand';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import styles from './Entry.module.scss';
import KitopiLogo from './KitopiLogo.svg?react';

const Entry = () => {
  const { data } = useBrand();
  const { queryParams } = useValidateQueryParams();
  const { tableNumber, counterNumber, outletId, brandId } = queryParams();

  const pdfUrl = `${VITE_INITIAL_CDN_PATH}/menus/${brandId}.pdf`;

  const payNowLink = tableNumber
    ? `/entry?outletId=${outletId}&tableNumber=${tableNumber}&brandId=${brandId}`
    : `/entry?outletId=${outletId}&counterNumber=${counterNumber}&brandId=${brandId}`;

  return (
    <div className={styles.entry}>
      <section className={styles.header}>
        <div className={styles.left}></div>
        <h1>
          <KitopiLogo />
        </h1>
        <div className={styles.tableNumber}>
          {tableNumber ? (
            <span>Table {tableNumber?.replace('T', '')}</span>
          ) : (
            <span>{counterNumber}</span>
          )}
        </div>
      </section>
      <section className={styles.body}>
        <div className={styles.promoContainer}>
          <p className={styles.promo}>
            Get <span className={styles.highlited}>20% cashback</span> on every single order in the
            Kitopi app
          </p>
        </div>
      </section>
      <section className={styles.footer}>
        <div className={styles.footerMenuLogo}>
          <img src={data?.logoUris['200_200']} alt={data?.name} />
        </div>
        <a className={styles.payNow} href={payNowLink}>
          Pay now
        </a>
        <a href={pdfUrl} className={styles.white}>
          View menu
        </a>
        <Terms />
      </section>
    </div>
  );
};

export default <Entry />;
