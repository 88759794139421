import { SUCCESS_URL } from '@/App';
import { OurBrands } from '@/components/OurBrands/OurBrands';
import { SuccessTitle } from '@/components/SuccessTitle/SuccessTitle';
import { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './PaymentConfirmation.module.scss';

const PaymentConfirmationNew = () => {
  const screenContainerRef = useRef<HTMLDivElement>(null);
  const query = new URLSearchParams(useLocation().search);
  const orderId = query.get('order') || '';
  const brandName = query.get('brand');
  const cashbackEstimate = query.get('cashback');

  useEffect(() => {
    (window.dataLayer || []).push({
      event: 'purchase_completed',
      orderId: orderId,
      brandName: brandName,
      success_status: SUCCESS_URL,
    });
  }, [orderId, brandName]);

  useEffect(() => {
    const element = screenContainerRef.current;
    if (!element) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  if (!orderId || !brandName || !cashbackEstimate) {
    return <Navigate to="/404" />;
  }

  return (
    <div className={styles.root} ref={screenContainerRef}>
      <div className={styles.success}>
        <SuccessTitle />

        <OurBrands />

        <div className={styles.disclaimer}>Please ask waiter for receipt</div>
      </div>
    </div>
  );
};

export default PaymentConfirmationNew;
