import Modal3ds from '@/components/CheckoutPayment/components/Modal3DS';
import { useCheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import useScrollIntoView from '@/components/CheckoutPayment/hooks/useScrollIntoView';
import Button from '@/components/SharedButton/Button';
import { currency } from '@/constants/constants';
import { usePayment } from '@/hooks/usePayment';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import formatNumber from '@/utils/formatNumber';
import { CardFrame, FrameValidationChangedEvent, Frames } from 'frames-react';
import styles from './CardPayment.module.scss';

const CardPayment = () => {
  const { state, dispatch, handleCardTokenized } = useCheckoutPaymentState();
  const { paymentState } = usePayment();
  const { queryParams } = useValidateQueryParams();
  const { outletId, tableNumber, counterNumber } = queryParams();

  const buttonRef = useScrollIntoView(state.isButtonDisabled);

  const handleButtonClick = () => {
    dispatch({ type: 'SET_PAYMENT_LOADING', payload: true });
    Frames.submitCard();
    dispatch({ type: 'SET_BUTTON_DISABLED', payload: true });
  };

  const handleValidationChange = () => {
    dispatch({ type: 'SET_BUTTON_DISABLED', payload: !Frames.isCardValid() });
  };

  const ERROR_MESSAGES: Record<FrameValidationChangedEvent['element'], string> = {
    'card-number': 'Please enter a valid card number',
    'expiry-date': 'Please enter a valid expiry date',
    cvv: 'Please enter a valid CVV code',
  };

  const handleFrameValidationChange = (event: FrameValidationChangedEvent) => {
    if (!event.isValid && !event.isEmpty) {
      dispatch({
        type: 'SET_ERROR_MESSAGES',
        payload: [ERROR_MESSAGES[event.element]],
      });
    }
    if (event.isValid) {
      dispatch({ type: 'SET_ERROR_MESSAGES', payload: [] });
    }
  };

  if (!paymentState.total) return null;

  return (
    <div className={styles.root}>
      {state.publicKey && (
        <Frames
          config={{
            publicKey: state.publicKey,
            style: {
              base: { fontSize: '16px', width: '50px' },
            },
          }}
          cardTokenized={handleCardTokenized}
          cardValidationChanged={handleValidationChange}
          frameValidationChanged={handleFrameValidationChange}
        >
          <CardFrame id="expiry-date" className={styles.cardFrame} />
        </Frames>
      )}
      <div className={styles.errorMessage}>
        {state.errorMessages.length > 0 &&
          state.errorMessages.map((msg: string) => <p key={msg}>{msg}</p>)}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          ref={buttonRef}
          label={`Pay ${currency} ${formatNumber(paymentState.total)}`}
          onClick={handleButtonClick}
          disabled={state.isButtonDisabled}
          loading={state.isPaymentLoading}
        />
      </div>
      {outletId &&
        tableNumber &&
        paymentState.paymentMethod === 'card' &&
        paymentState.threeDSUrl && (
          <Modal3ds outletId={outletId} tableNumber={tableNumber} counterNumber={counterNumber} />
        )}
    </div>
  );
};

export default CardPayment;
