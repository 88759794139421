import { Bill } from '@/interfaces/Bill';
import { fetch } from '@/utils/fetch';

interface Props {
  outletId: string;
  counterNumber: string;
  signal: AbortSignal;
}

export const getOrderDetails = ({ outletId, counterNumber, signal }: Props): Promise<Bill> =>
  fetch
    .get(`/personalization-public/dine-in/outlets/${outletId}/counters/${counterNumber}`, {
      signal,
    })
    .then(response => response.data);
