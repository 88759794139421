import Modal3ds from '@/components/CheckoutPayment/components/Modal3DS';
import { getGooglePayButtonOptions } from '@/components/CheckoutPayment/components/googlePayOptions';
import { useCheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import useScrollIntoView from '@/components/CheckoutPayment/hooks/useScrollIntoView';
import Overlay from '@/components/Modal/Overlay';
import useErrors from '@/hooks/useErrors';
import { usePayment } from '@/hooks/usePayment';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import GooglePayButton from '@google-pay/button-react';
import styles from './GooglePay.module.scss';

const GooglePay = () => {
  const { state, handleGooglePaySuccess } = useCheckoutPaymentState();
  const { paymentState, setPaymentState } = usePayment();
  const { queryParams } = useValidateQueryParams();
  const { outletId, tableNumber, counterNumber } = queryParams();
  const { setErrorMessages } = useErrors();

  const googlePayButtonOptions = getGooglePayButtonOptions(state, paymentState.total);
  const buttonRef = useScrollIntoView(state.isButtonDisabled) as React.RefObject<HTMLDivElement>;

  const handleLoadPaymentData = (paymentData: google.payments.api.PaymentData) => {
    handleGooglePaySuccess(paymentData);
    setPaymentState(prevState => ({
      ...prevState,
      isOverlayVisible: true,
    }));
  };

  return (
    <div className={styles.root} ref={buttonRef}>
      {state.publicKey && (
        <>
          {paymentState.paymentMethod === 'google-pay' &&
            !paymentState.threeDSUrl &&
            paymentState.isOverlayVisible && <Overlay />}
          <GooglePayButton
            {...googlePayButtonOptions}
            onLoadPaymentData={handleLoadPaymentData}
            onError={() => {
              setErrorMessages(['Google Pay failed. Please try again.']);
              setPaymentState(prevState => ({
                ...prevState,
                isOverlayVisible: false,
              }));
            }}
          />
          {outletId &&
            tableNumber &&
            paymentState.paymentMethod === 'google-pay' &&
            paymentState.threeDSUrl && (
              <Modal3ds
                outletId={outletId}
                tableNumber={tableNumber}
                counterNumber={counterNumber}
              />
            )}
        </>
      )}
    </div>
  );
};

export default GooglePay;
