import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePayment } from '@/hooks/usePayment';
import { Bill } from '@/interfaces/Bill';
import { PaymentStatus } from '@/interfaces/Payment';

interface State {
  brandName: string | null;
}

const usePaymentStatusRedirection = (
  paymentStatusData: PaymentStatus | undefined,
  bill: Bill | undefined,
  state: State,
  outletId: string,
  tableNumber?: string,
  counterNumber?: string
) => {
  const { setPaymentState, paymentState } = usePayment();
  const navigate = useNavigate();

  const paymentStatusRef = useRef(paymentStatusData?.paymentStatus);
  const paymentStateRef = useRef(paymentState);

  useEffect(() => {
    const paymentStatus = paymentStatusData?.paymentStatus;

    if (paymentStatusRef.current === paymentStatus) return;
    paymentStatusRef.current = paymentStatus;

    if (paymentStatus === 'PENDING_3DS_AUTHENTICATION') {
      const threeDSUrl = paymentStatusData?.additionalInfo?.threeDSUrl;
      if (threeDSUrl) {
        setPaymentState(prevState => ({
          ...prevState,
          isModalOpen: true,
          threeDSUrl,
        }));
      }
    }

    if (paymentStatus === 'COMPLETED') {
      setPaymentState(prevState => ({ ...prevState, isModalOpen: false }));
      if (counterNumber) {
        navigate(
          `/checkout/success/?order=${bill?.orderId}&brand=${state.brandName}&cashback=${bill?.estimatedCashback}&outletId=${outletId}&counterNumber=${counterNumber}`
        );
      }
      if (tableNumber) {
        navigate(
          `/checkout/success/?order=${bill?.orderId}&brand=${state.brandName}&cashback=${bill?.estimatedCashback}&outletId=${outletId}&tableNumber=${tableNumber}`
        );
      }
    }

    if (paymentStatus === 'FAILED' || paymentStatus === 'REJECTED' || paymentStatus === 'UNKNOWN') {
      navigate(`/checkout/failed`);
    }
  }, [
    paymentStatusData,
    bill,
    state.brandName,
    navigate,
    setPaymentState,
    outletId,
    tableNumber,
    counterNumber,
  ]);

  useEffect(() => {
    paymentStateRef.current = paymentState;
  }, [paymentState]);
};

export default usePaymentStatusRedirection;
