import { getOrderDetails } from '@/api/getOrderDetails';
import { getTableDetails } from '@/api/getTableDetails';
import { queryKeys } from '@/constants/queryKeys';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useQuery } from '@tanstack/react-query';

export const useBill = () => {
  const { queryParams } = useValidateQueryParams();
  const { outletId, tableNumber, counterNumber } = queryParams();

  if (!tableNumber && !counterNumber) {
    throw new Error('Counter number or table number are required');
  }

  if (!outletId) {
    throw new Error('Outlet ID is required');
  }

  return useQuery({
    queryKey: queryKeys.bill({ outletId, tableNumber }),
    queryFn: ({ signal }) => {
      if (counterNumber) {
        return getOrderDetails({ outletId, counterNumber, signal });
      }
      if (tableNumber) {
        return getTableDetails({ outletId, tableNumber, signal });
      }
      throw new Error('Counter number or table number are required');
    },
    staleTime: 0,
    enabled: !!outletId && (!!tableNumber || !!counterNumber),
  });
};
