const useValidateQueryParams = () => {
  const searchParams = new URLSearchParams(location.search);

  const queryParams = () => {
    const outletId = searchParams.get('outletId');
    const intentId = searchParams.get('intentId');
    const counterNumber = searchParams.get('counterNumber') || undefined;
    const tableNumber = searchParams.get('tableNumber')
      ? searchParams.get('tableNumber')?.toUpperCase()
      : '';
    const brandId = searchParams.get('brandId');
    return { outletId, tableNumber, brandId, intentId, counterNumber };
  };

  const isValid = () => {
    const { outletId, tableNumber, brandId, counterNumber } = queryParams();
    return !!outletId && (!!tableNumber || !!counterNumber) && !!brandId;
  };

  return { isValid, queryParams };
};

export default useValidateQueryParams;
